





























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Permissions, User } from '../..//lib/models/auth';

@Component
export default class AuthorizedMenuComponent extends Vue {
  @Prop(Object)
  user!: User;

  get permissions(): Permissions {
    return this.user.permissions;
  }

  get canTwitter(): boolean {
    return this.permissions.includes('twitter');
  }

  get canAdmin(): boolean {
    return this.permissions.includes('admin');
  }
}
