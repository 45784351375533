











































































import { Vue, Component, Prop } from 'vue-property-decorator';

import EventSubmissionStatus from './EventSubmissionStatusComponent.vue';
import EventScheduleStatus from './EventScheduleStatusComponent.vue';
import EventSelectionStatus from './EventSelectionStatusComponent.vue';
import EventClosedStatus from './EventClosedStatusComponent.vue';
import { Event } from '../../lib/models/event';

@Component({
  components: {
    EventSubmissionStatus,
    EventScheduleStatus,
    EventSelectionStatus,
    EventClosedStatus,
  }
})
export default class RecentlyEventComponent extends Vue {
  @Prop(Object)
  readonly event!: Event | null;

  @Prop(Boolean)
  readonly inSpa!: boolean;

  get startDateString(): string {
    if (!this.event?.startAt) {
      return '';
    }

    const time = this.event.startAt.toLocaleTimeString([], {hour12: false});
    const splitTime = time.split(':');
    return `${this.event.startAt.toLocaleDateString()} ${splitTime[0]}:${splitTime[1]}`
  }

  get endDateString(): string {
    if (!this.event?.endAt) {
      return '';
    }

    const time = this.event.endAt.toLocaleTimeString([], {hour12: false});
    const splitTime = time.split(':');
    return `${this.event.endAt.toLocaleDateString()} ${splitTime[0]}:${splitTime[1]}`
  }

  get selectionConfirmed(): boolean {
    return !(this.event?.status === 'freshed');
  }

  get scheduleConfirmed(): boolean {
    return (this.event?.status === 'scheduled' || this.event?.status === 'closed');
  }

  get eventClosed(): boolean {
    return this.event?.status === 'closed';
  }
}
