














import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '../../lib/models/auth';

@Component
export default class AuthUserLabelComponent extends Vue {
  @Prop(Object)
  user!: User;
}
