





























import { Vue, Component } from 'vue-property-decorator';

import EventInformation from './EventInfomationComponent.vue';
import LinearLoading from '../LinearLoadingComponent.vue';
import FadeTransition from '../FadeTransitionComponent.vue';
import { Event } from '../../lib/models/event';
import { apiModule } from '../../modules/api';

@Component({
  components: {
    EventInformation,
    LinearLoading,
    FadeTransition,
  }
})
export default class RecentlyEventComponent extends Vue {
  events: Event[] = [];

  async created(): Promise<void> {
    const response = await apiModule.getActiveEvents();
    response.sort((l, r) => {
      return l.startAt.valueOf() - r.startAt.valueOf();
    });

    this.events = response;
    if (this.events.length === 0) {
      const latest = await apiModule.getLatestEvent();
      this.events.push(latest);
    }
  }

}
