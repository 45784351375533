







































import { Vue, Component } from 'vue-property-decorator';

import RecentlyEvent from '../components/schedule/RecentlyEventComponent.vue';

@Component({
  components: {
    RecentlyEvent
  }
})
export default class TopApp extends Vue { }
