














































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import AuthLoginButton from '../components/auth/AuthLoginButtonComponent.vue';
import AuthorizedMenu from '../components/auth/AuthorizedMenuComponent.vue';
import AuthUserLabel from '../components/auth/AuthUserLabelComponent.vue';

import { apiModule } from '../modules/api';
import { authModule } from '../modules/auth';
import { User } from '../lib/models/auth';

@Component({
  components: {
    AuthorizedMenu,
    AuthLoginButton,
    AuthUserLabel,
  }
})
export default class OmeApp extends Vue {
  @Prop(String)
  readonly apiHost!: string;
  @Prop(String)
  readonly bearer!: string;
  @Prop(String)
  readonly loginUrl!: string;
  @Prop(String)
  readonly twitchAuthUri!: string;
  @Prop(String)
  readonly discordInvite!: string;

  get user(): User|null {
    return authModule.authUser;
  }

  async created(): Promise<void> {
    apiModule.updateHost(this.apiHost);
    if (this.bearer) {
      apiModule.updateBearerToken(this.bearer);

      try {
        authModule.authMe();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
