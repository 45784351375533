









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AuthLoginButtonComponent extends Vue {
  @Prop(String)
  loginUrl!: string;
}
