
























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EventSelectionStatusComponent extends Vue {
  @Prop(Boolean)
  readonly confirmed!: boolean;

  @Prop(Boolean)
  readonly isMobile!: boolean;
}
